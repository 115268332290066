var render = function () {
  var _vm$data, _vm$data$mealPlanSche, _vm$data2, _vm$data2$mealPlanSch, _vm$activeProgram, _vm$data3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "height": "100%",
      "flex-direction": "column",
      "margin-inline": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem 1rem 80px 1rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', 'auto']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbLabel
    }
  })], 1), _vm.isLoadingData ? _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding": "128px 24px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl",
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Rencana Makan... ")])], 1)], 1) : !_vm.isHaveMealPlanMonths ? _c('EmptyState', {
    attrs: {
      "title": "Kamu belum memiliki program aktif",
      "description": "Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu.",
      "button-text": "Beli Program",
      "button-route": "client.select-program"
    }
  }) : !((_vm$data = _vm.data) !== null && _vm$data !== void 0 && (_vm$data$mealPlanSche = _vm$data.mealPlanSchedules) !== null && _vm$data$mealPlanSche !== void 0 && _vm$data$mealPlanSche.length) ? _c('EmptyState', {
    attrs: {
      "title": "Rencana makanmu masih kosong",
      "description": "Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!",
      "button-text": "Beranda",
      "button-route": "client.index"
    }
  }) : _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": ['#FFF', 'transparent'],
      "padding": ['16px', '0px']
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "2rem",
      "margin-bottom": ['0.5rem', '1rem']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "padding": "0px",
      "width": "30px",
      "height": "30px",
      "min-width": "30x",
      "display": ['none', 'flex'],
      "align-items": "center",
      "margin-top": "7px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-left.svg'),
      "height": "20px",
      "width": "20px"
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$mealPlanSch = _vm$data2.mealPlanSchedules) === null || _vm$data2$mealPlanSch === void 0 ? void 0 : _vm$data2$mealPlanSch.length) >= 1 ? _c('DownloadMealPlan', {
    attrs: {
      "program-id-local": (_vm$activeProgram = _vm.activeProgram) === null || _vm$activeProgram === void 0 ? void 0 : _vm$activeProgram.id,
      "month": _vm.$route.params.month
    }
  }) : _vm._e(), _c('c-text', {
    attrs: {
      "color": "neutral.black",
      "font-size": ['18px', '20px'],
      "font-weight": "700",
      "text-align": "center",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Jam Makan ")])], 1)], 1), _c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": ['16px', '20px'],
      "margin-bottom": "24px"
    }
  }, _vm._l((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.mealPlanSchedules, function (item, i) {
    return _c('c-flex', {
      key: i,
      attrs: {
        "width": "100%",
        "align-items": "center",
        "background-color": "#FFF",
        "padding": ['16px', '20px'],
        "border-radius": "8px",
        "gap": "8px",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
        "cursor": item !== null && item !== void 0 && item.isSkipped ? 'not-allowed' : 'pointer',
        "opacity": item !== null && item !== void 0 && item.isSkipped ? '0.7' : '1'
      },
      on: {
        "click": function click($event) {
          return _vm.onClickItem(item);
        }
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-grow": "1",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "margin-right": "1rem"
      }
    }, [_c('c-image', {
      attrs: {
        "width": ['40px', '60px'],
        "height": ['40px', '60px'],
        "src": _vm.constants.mealTileIdToImageUrl[item === null || item === void 0 ? void 0 : item.mealTime]
      }
    })], 1), _c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "margin-right": "auto"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "neutral.black",
        "font-size": ['14px', '18px'],
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(_vm.constants.mealTileIdToLabel[item === null || item === void 0 ? void 0 : item.mealTime] || '-') + " ")]), !(item !== null && item !== void 0 && item.isSkipped) ? _c('c-text', {
      attrs: {
        "color": "neutral.black",
        "font-size": ['12px', '16px'],
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(item !== null && item !== void 0 && item.caloriesAmount ? Math.round(Number(item === null || item === void 0 ? void 0 : item.caloriesAmount)) + ' kkal' : '-') + " ")]) : _vm._e()], 1), item !== null && item !== void 0 && item.isSkipped ? _c('c-flex', {
      attrs: {
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "neutral.superDarkGray",
        "font-size": ['12px', '16px'],
        "font-weight": "400"
      }
    }, [_vm._v(" Tidak Tersedia ")]), _c('c-box', {
      attrs: {
        "flex-shrink": "0",
        "width": ['12px', '18px'],
        "height": ['12px', '18px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-forbidden.svg'),
        "width": "100%",
        "height": "100%",
        "stroke": "#333333"
      }
    })], 1)], 1) : _c('c-box', {
      attrs: {
        "font-size": ['12px', '16px'],
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(_vm.format24Time(item === null || item === void 0 ? void 0 : item.startTime)) + " - " + _vm._s(_vm.format24Time(item === null || item === void 0 ? void 0 : item.endTime)) + " ")])], 1), !(item !== null && item !== void 0 && item.isSkipped) ? _c('c-box', {
      attrs: {
        "flex-shrink": "0",
        "width": "20px",
        "height": "20px"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icon-chevron-right.svg'),
        "stroke": "#000"
      }
    })], 1) : _vm._e()], 1);
  }), 1), _c('c-box', [_c('c-flex', {
    attrs: {
      "gap": "16px",
      "flex-direction": ['column', 'row']
    }
  }, [_c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "warning",
      "label": "Dibatasi"
    },
    scopedSlots: _vm._u([{
      key: "items",
      fn: function fn() {
        var _vm$data4, _vm$data4$mealRecomme;

        return [_c('c-box', {
          attrs: {
            "font-size": ['14px', '16px']
          },
          domProps: {
            "innerHTML": _vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$mealRecomme = _vm$data4.mealRecommendations) === null || _vm$data4$mealRecomme === void 0 ? void 0 : _vm$data4$mealRecomme.limited)
          }
        })];
      },
      proxy: true
    }])
  }), _c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "danger",
      "label": "Dilarang"
    },
    scopedSlots: _vm._u([{
      key: "items",
      fn: function fn() {
        var _vm$data5, _vm$data5$mealRecomme;

        return [_c('c-box', {
          attrs: {
            "font-size": ['14px', '16px']
          },
          domProps: {
            "innerHTML": _vm._s((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$mealRecomme = _vm$data5.mealRecommendations) === null || _vm$data5$mealRecomme === void 0 ? void 0 : _vm$data5$mealRecomme.avoided)
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenPopup,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "has-footer": "",
      "size": "800px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "justify-content": "center",
            "align-items": "center",
            "padding": "16px 20px",
            "position": "relative"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['14px', '18px'],
            "font-weight": "500",
            "color": "neutral.black"
          }
        }, [_vm._v(" Yay, panduan makanmu sudah ada ")]), _c('c-flex', {
          attrs: {
            "align-items": "center",
            "gap": "12px",
            "position": "absolute",
            "top": "10px",
            "right": "10px"
          }
        }, [_c('c-button', {
          attrs: {
            "variant": "ghost",
            "width": ['20px', '30px'],
            "min-width": ['20px', '30px'],
            "height": "30px",
            "margin": "0",
            "padding": "0"
          },
          on: {
            "click": _vm.handleClosePopupGreeting
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "close",
            "size": ['10px', '17px']
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$popupDetail, _vm$popupDetail2, _vm$popupDetail$descr;

        return [_c('c-box', [_c('c-box', {
          attrs: {
            "width": "100%",
            "max-height": "600px",
            "overflow": "auto"
          }
        }, [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [(_vm$popupDetail = _vm.popupDetail) !== null && _vm$popupDetail !== void 0 && _vm$popupDetail.photoUrl ? _c('c-image', {
          attrs: {
            "height": ['120px', '200px'],
            "object-fit": "cover",
            "src": (_vm$popupDetail2 = _vm.popupDetail) === null || _vm$popupDetail2 === void 0 ? void 0 : _vm$popupDetail2.photoUrl,
            "alt": "image question"
          }
        }) : _vm._e()], 1)], 1), _c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": ['30px 40px 20px 40px', '30px 100px 20px 100px'],
            "text-align": "center",
            "font-size": ['14px', '18px']
          }
        }, [_c('c-box', {
          attrs: {
            "as": "ol"
          }
        }, _vm._l((_vm$popupDetail$descr = _vm.popupDetail.description) === null || _vm$popupDetail$descr === void 0 ? void 0 : _vm$popupDetail$descr.split('\n'), function (item, index) {
          return _c('c-box', {
            key: index,
            attrs: {
              "as": "li",
              "text-align": "left"
            }
          }, [_vm._v(" " + _vm._s(item) + " ")]);
        }), 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "80%",
            "margin": "auto"
          },
          on: {
            "click": _vm.onClickCekPanduanMakan
          }
        }, [_vm._v(" Cek Panduan Makan ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }